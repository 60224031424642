import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import PostActions from "../PostActions/PostActions";
import "./HeadLineSlideShow.scss";

const HeadLineSlideShow = ({ posts, openModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let index = 0;
    const timeout = setInterval(() => {
      if (posts) {
        if (posts.length - 1 === index) {
          index = 0;
          setCurrentIndex(index);
        } else {
          index += 1;
          setCurrentIndex(index);
        }
      }
    }, 5000);

    return () => {
      clearInterval(timeout);
    };
  }, [posts]);

  return (
    <>
      {/* {onclickhendler && post && (
        <Modal
          handleClose={() => {
            setOnclickhendler(false);
            document.body.classList.remove("noscroll");
          }}
        >
          <PostContent post={post} />
        </Modal>
      )} */}
      <div className="container slide-show-height">
        <div className="slide-show">
          <div className="row justify-content-center">
            <div className="col-md-10 mb-4">
              <div className="btn-tag">Top Tech Headlines</div>
            </div>
            <div className="col-md-10">
              <Carousel
                fade
                activeIndex={currentIndex}
                controls={false}
                indicators={false}
              >
                {posts.map((post, index) => {
                  return (
                    <Carousel.Item key={index}>
                      <div>
                        <h1 className="title clamp-3"> {post.title}</h1>
                        <p className="description clamp-3">
                          {post.description}
                        </p>
                        <PostActions
                          postId={post.postId}
                          postTitle={post.title}
                          publishDate={post.publishDate}
                          statistics={post.statistics}
                          isLikedByUser={post.isLikedByUser}
                          openModal={() => openModal(post)}
                          light="true"
                          condensed="true"
                        />
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>

            {/* {posts.map((post, index) => {
              return (
                index === currentIndex && (
                  <div
                    className={
                      index === currentIndex
                        ? "col-md-10 post-enter-active"
                        : "col-md-10 post-exit-active"
                    }
                  >
                    <h1 className="title clamp-3"> {post.title}</h1>
                    <p className="description clamp-3">{post.description}</p>
                    <PostActions
                      postId={post.postId}
                      postTitle={post.title}
                      publishDate={post.publishDate}
                      statistics={post.statistics}
                      openModal={() => setOnclickhendler(true)}
                      light="true"
                      condensed="true"
                    />
                  </div>
                )
              );
            })} */}
          </div>
        </div>
      </div>
    </>
  );
};
export default HeadLineSlideShow;
