
import { useEffect } from "react";

/**
 * Hook which adds an event `listener` function to the document object based on a boolean `condition` and the `eventType`.
 * @param {*} eventType  The event type to listen for.
 * @param {*} listener The method to call when the event is triggered.
 * @param {*} condition  The condition determining if the event listener should be added.
 */
export const useConditionalEventListener = (eventType, listener, condition) => {
    useEffect(() => {
        if (!condition) return;   
        document.addEventListener(eventType, listener);
    
        return () => {
        document.removeEventListener(eventType, listener);
        };
    }, [eventType, listener, condition]);
    };
