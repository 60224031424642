import axios from "axios";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Modal from "../../utils/Modal";
import Loader from "../Common/Loader";
import { useConditionalEventListener } from "../../hooks/useConditionalEventListener";
import "./QuarterlyPerspective.scss";

const QuarterlyPerspective = ({ quarterlyPerspective, showPerspective }) => {
  const [numPage, setNumPage] = useState(0);
  // const [pageNumber, setPageNumber] = useState(1);
  const [showQuarterlyPerspective, setShowQuarterlyPerspective] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(quarterlyPerspective[0].tabName);
  const primaryBtn = "btn btn-outline-primary";
  const primaryBtnActive = "btn btn-outline-primary active";

  function onDocumentLoadSuccess({ numPages }) {
    setNumPage(numPages);
    // setPageNumber(1);
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);
    return () => clearTimeout(timer);
  }
  // function changePage(offSet) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  // }

  // function changePageBack() {
  //   changePage(-1);
  // }
  // function changePageNext() {
  //   changePage(+1);
  // }

  const onPerspectiveView = () => {
    document.body.classList.add("noscroll");
    setShowQuarterlyPerspective(true);
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        quaterlyPerspectiveTimestamp: new Date(Date.now()).getTime(),
        className: "quarterly-perspective",
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then(() => {
        console.log("Quarterly Perspective success");
      })
      .catch((error) => {
        console.log(error);
      });
    setShowLoader(true);
  };

  const handleModalClose = () => {
    setShowQuarterlyPerspective(false);
    document.body.classList.remove("noscroll");
  }

  const handleEscapeKey = (event) => {
    if (event.key === "Escape") {
      handleModalClose();
    }
  };
  useConditionalEventListener("keydown", handleEscapeKey, showQuarterlyPerspective);

  return (
    <div>
      {showPerspective && (
        <div className="quarterly-perspective">
          <div className="container-fluid">
            <h2>{quarterlyPerspective[0].title}</h2>
            <p>
              <i style={{ fontSize: "20px" }}>
                Assessment of impactful topics and/or trends shaping the health
                tech landscape over the past quarter to inform Amgen's strategic
                choices.
              </i>
              <br />
              <i>
                Powered By R&D AI Strategy and Execution.
                <br />
              </i>
            </p>
            <p>
              <button
                onClick={() =>  onPerspectiveView()}
                className="btn btn-outline-primary"
              >
                View
              </button>
            </p>
          </div>
        </div>
      )}

      {showQuarterlyPerspective && (
        <Modal
          handleClose={handleModalClose}
          pdfView
        >
          <div className="pdf-modal">
            {showLoader ? <Loader showLoader /> : null}
            <div className="tabButton">
              {quarterlyPerspective.map((perspective) => {
                return (
                  <button
                    key={perspective.tabName}
                    className={activeTab === perspective.tabName ? primaryBtnActive : primaryBtn}
                    onClick={() => {
                      if (!(activeTab === perspective.tabName)) {
                        setNumPage(0);
                        setActiveTab(perspective.tabName);
                        setShowLoader(true);
                        setTimeout(() => {
                          setShowLoader(false);
                        }, 3000);
                      }
                    }}
                  >
                    {perspective.tabName}
                  </button>
                );
              })}
            </div>
            {quarterlyPerspective.map((perspective) => {
              return (
                <div key={perspective.tabName}>
                  {activeTab === perspective.tabName && ( 
            <center style={{ padding: "10px 5px" }}>
              <div>
                <Document
                  file={`${window.location.origin}/${perspective.perspectiveFileUrl}`}
                  loading=""
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(new Array(numPage), (el, index) => (
                    <Page
                      key={`Page_${index + 1}`}
                      pageNumber={index + 1}
                      loading=""
                    ></Page>
                  ))}
                </Document>
              </div>
            </center>
            )}
                </div>
              );
            })}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default QuarterlyPerspective;
