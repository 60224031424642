import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import ConfigContext from "../../ConfigContext";
import Modal from "../../utils/Modal";
import CategoryTab from "../CategoryTab/CategoryTab";
import Loader from "../Common/Loader";
import Feedback from "../Feedback/Feedback";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PostContent from "../PostContent/PostContent";
import Post from "../Posts/Posts";
import PostSlider from "../PostSlider/PostSlider";
import QuarterlyPerspective from "../QuarterlyPerspective/QuarterlyPerspective";
import "./Home.scss";

function Home() {
  const { configData } = useContext(ConfigContext);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [focusRef, setFocusRef] = useState(false);
  const [tabContentHeight, setTabContentHeight] = useState("auto");
  const [mobile, setMobile] = useState(false);
  const [childCategories, setChildCategories] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [categories, setCategories] = useState([]);
  const [subscribedCategories, setSubscribedCategories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  //   const [allPostIds, setallPostIds] = useState([]);
  //   const [currentPageSize, setcurrentPageSize] = useState(5);
  const [quarterlyPerspective, setQuarterlyPerspective] = useState([]);
  //   const [featuredPostlength, setfeaturedPostlength] = useState(0);
  const [featured, setFeatured] = useState([]);
  const [otherNewsCategories, setOtherNewsCategories] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const categoryCondensedDisplayWidth = 992;
  const contentRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState({});
  const [appReleaseIndex, setAppReleaseIndex] = useState(0);
  const [isHashLoaded, setIsHashLoaded] = useState(false);

  const categoryInfo = {
    694: {
      icon: "../../images/icons/big-tech.svg",
      image: "../../images/exports/category_bigTech.png",
    },
    700: {
      icon: "../../images/icons/big-tech.svg",
      image: "../../images/exports/category_bigTech.png",
    },
    696: {
      icon: "../../images/icons/startups.svg",
      image: "../../images/exports/category_startups.png",
    },
    705: {
      icon: "../../images/icons/startups.svg",
      image: "../../images/exports/category_startups.png",
    },
    695: {
      icon: "../../images/icons/pharma-peers.svg",
      image: "../../images/exports/category_pharmaPeers.png",
    },
    704: {
      icon: "../../images/icons/pharma-peers.svg",
      image: "../../images/exports/category_pharmaPeers.png",
    },
    697: {
      icon: "../../images/icons/payers-providers.svg",
      image: "../../images/exports/category_payersProviders.png",
    },
    706: {
      icon: "../../images/icons/payers-providers.svg",
      image: "../../images/exports/category_payersProviders.png",
    },
    703: {
      icon: "../../images/icons/regulator-policy.svg",
      image: "../../images/exports/category_regulatoryPolicy.png",
    },
    707: {
      icon: "../../images/icons/regulator-policy.svg",
      image: "../../images/exports/category_regulatoryPolicy.png",
    },
  };

  const hasPerspective = (post) => {
    let result;
    let hasPerspective = false;
    const regMatch = /<blockquote.+>(.+)<\/blockquote>/g;
    while ((result = regMatch.exec(post || "")) !== null) {
      if (
        result &&
        result[0] &&
        result[0].toLowerCase().includes("dh&i perspective")
      ) {
        hasPerspective = true;

        post = post.replace(
          result[0],
          '<div class="perspective"><span class="tooltiptext">Take a deeper dive into this article through commentary provided by DH&I, where we assess this headline in context of other market activity and interpret the ‘so what’ for Amgen</span>' +
            result[0] +
            "</div>"
        );
      }
    }
    return hasPerspective ? post : false;
  };

  const getPostByCategory = (categoryId) => {
    const category =
      (posts || []).find((category) => categoryId === category.id) || {};
    return (category.posts || []).slice(0, 16);
  };

  const checkCategoryDisplayMode = () => {
    if (window.innerWidth >= categoryCondensedDisplayWidth) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  // const setSlideHeight = () => {
  //   // console.log(contentRef.current)
  //   // let el = document.getElementById("news-category");
  //   // if (mobile || !el) {
  //   //   setTabContentHeight("auto")
  //   //   return;
  //   // }

  //   // setTabContentHeight(contentRef.current.clientHeight + "px");
  //   setTabContentHeight("auto");
  // };

  useEffect(() => {
    if (configData) {
      setStartDate(
        dayjs(configData.app_release[appReleaseIndex].startDate).format(
          "MM.DD.YY"
        ) || ""
      );
      setEndDate(
        dayjs(configData.app_release[appReleaseIndex].endDate).format(
          "MM.DD.YY"
        ) || ""
      );
      setQuarterlyPerspective(configData.quarterlyPerspective);
      // loginInfo();
      checkCategoryDisplayMode();
      window.addEventListener("resize", checkCategoryDisplayMode);
      setShowLoader(true);
      const getAllCategories = async () => {
        const config = {
          method: "get",
          url: `${configData.APP_URL}/categories`,
        };
        const {
          data: { categories: allCategories },
        } = await axios(config);
        setAllCategories(allCategories);
      };
      const getSubscribedCategories = async (id) => {
        const config = {
          method: "get",
          url: `${configData.APP_URL}/user/${id}/subscriptions`,
        };
        const {
          data: { categories: subscribedCategories },
        } = await axios(config);
        setSubscribedCategories(subscribedCategories);
        if (subscribedCategories.length === 0) {
          const childCategories = configData.childCategoryIds;
          subscribeCategory(childCategories);
        }
      };
      getAllCategories();
      axios
        .post(`${configData.APP_URL}/user/email`, {
          email: localStorage.getItem("registerE"),
        })
        .then((res) => {
          if (res.data) {
            localStorage.setItem("userId", res.data.id);
            localStorage.setItem(
              "functionName",
              res.data.tags.FunctionName.value
            );
            getSubscribedCategories(res.data.id);
            loginInfo(res.data.tags.FunctionName.value);
          }
        });
    }
    return () => {
      window.removeEventListener("resize", checkCategoryDisplayMode);
    };
  }, [configData]);

  useEffect(() => {
    if (subscribedCategories.length > 0) {
      // const downloadCategory = subscribedCategories.find(
      //   (category) => category.id === configData.parentCategoryId
      // );
      const childCategories = configData.childCategoryIds;
      const requireCategoryforSubscription = [];
      if (childCategories.length > 0) {
        childCategories.forEach((id) => {
          if (
            subscribedCategories.findIndex((childId) => childId.id === id) ===
            -1
          ) {
            requireCategoryforSubscription.push(id);
          }
        });
        if (requireCategoryforSubscription.length > 0) {
          subscribeCategory(requireCategoryforSubscription);
        }
      }
      //  else {
      //   childCategories.unshift(692);
      //   subscribeCategory(childCategories);
      // }
    }
  }, [subscribedCategories]);

  useEffect(() => {
    function handleModalKeyListener(event) {
      if (event.key === "Escape") {
        setFocusRef(true)
        setIsModalOpen(false);
        document.body.classList.remove("noscroll");
      }
    }
    if (!isModalOpen) return
      document.addEventListener("keydown", handleModalKeyListener)
     
      return () => {
        document.removeEventListener("keydown", handleModalKeyListener)
      }
  }, [isModalOpen]); 

  const subscribeCategory = (categories) => {
    const userId = Number(localStorage.getItem("userId"));
    const payload = {
      id: userId,
      categoryIds: categories,
    };
    axios
      .post(`${configData.APP_URL}/user/${userId}/subscriptions`, payload)
      .then((res) => {
        // console.log("subscribed categories", res.data);
      });
  };

  const getEachPost = async (childCategories, posts) => {
    let allPostIds = posts;
    let tempchildCategories = JSON.parse(JSON.stringify(childCategories));
    setCategories([]);
    setPosts([]);
    setFeatured([]);
    setOtherNewsCategories([]);
    let userId = Number(localStorage.getItem("userId"));
    axios
      .get(
        `${configData.APP_URL}/posts?ids=${allPostIds.join(
          ","
        )}&userId=${userId}`
      )
      .then((res) => {
        // setAllPosts(configData.posts);
        res.data.posts.forEach((data) => {
          const perspective = hasPerspective(data.content);
          if (perspective) {
            data.perspective = !!perspective;
            data.content = perspective;
          }
          //allPosts[data.postId] = data;
          if (data.categories && data.categories.length) {
            // Loop through the categories and add the post to each if exists
            data.categories.forEach(({ id: postCategoryId }) => {
              const categoryIndex = tempchildCategories.findIndex(({ id }) => {
                return id === postCategoryId;
              });
              if (categoryIndex > -1) {
                tempchildCategories[categoryIndex].posts.push(data);
              }
            });
          }
        });
        setAllPosts(res.data.posts);
        setCategories(tempchildCategories);
        setPosts(tempchildCategories);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    if (allCategories.length > 0) {
      let { childCategories } = allCategories.find(
        (category) => "the download" === category.name.toLowerCase()
      );
      // Filter out pending categories - this can be removed later
      childCategories = childCategories
        .filter((category) => {
          return !category.name.startsWith("Pending");
        })
        .map((category) => {
          return {
            ...category,
            posts: [],
            name: category.name.replace("Pending - ", ""),
          };
        });
      setChildCategories(childCategories);
      getEachPost(
        childCategories,
        configData.app_release[appReleaseIndex].posts
      );
    }
  }, [allCategories]);

  useEffect(() => {
    if (posts.length > 0) {
      const featured =
        (posts || []).find((category) => 693 === category.id) || {};
      setFeatured((featured.posts || []).slice(0, 6));
    }
  }, [posts]);

  useEffect(() => {
    if (categories.length > 0) {
      const sortList = [
        "big tech",
        "start ups",
        "biopharma peers",
        "payers providers",
        "regulatory & policy",
        "General",
      ];

      const NewsCategories = categories
        .filter((category) => -1 === [698, 693, 745].indexOf(category.id))
        .sort((a, b) => {
          return (
            sortList.indexOf(a.name.toLowerCase()) -
            sortList.indexOf(b.name.toLowerCase())
          );
        })
        .map((category) => {
          const current = categoryInfo[category.id] || {};
          return { ...category, ...current };
        });
      setOtherNewsCategories(NewsCategories);
      setSelectedSlide(NewsCategories[0].id);
      // setSlideHeight();
      if (window.location.hash && !isHashLoaded) {
        let post = allPosts.find(
          (post) => post.postId === window.location.hash.substr(1)
        );
        setIsHashLoaded(true);
        if (post) {
          openModal(post);
        } else {
          getPostDetailsByPostID(window.location.hash.substr(1));
        }
      }
    }
  }, [categories]);

  const getPostDetailsByPostID = (postId) => {
    setShowLoader(true);
    axios.get(`${configData.APP_URL}/post?id=${postId}`).then((res) => {
      setShowLoader(false);
      openModal(res.data);
    });
  };

  const loginInfo = (functionName) => {
    localStorage.setItem("articleReadCount", 0);
    const payload = [
      {
        className: "first-time-login",
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        functionName: functionName,
        loginTimestamp: new Date(Date.now()).getTime(),
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    localStorage.setItem("loginTimestamp", new Date(Date.now()).getTime());
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        // console.log(" success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = (post) => {
    setCurrentPost(post);
    setIsModalOpen(true);
    document.body.classList.add("noscroll");
    let initialCount = Number(localStorage.getItem("articleReadCount"));
    localStorage.setItem("articleReadCount", initialCount + 1);
    articleRead(post);
    // window.scrollTo(0)
    // if(window != undefined){
    //   window.scrollTo(0)
    // }
  };

  const articleRead = (post) => {
    const payload = [
      {
        userId: localStorage.getItem("registerE"),
        userName: localStorage.getItem("userName"),
        postId: post.postId,
        postTitle: post.title,
        articleReadTimestamp: new Date(Date.now()).getTime(),
        className: "article-read",
        publishDate: post.publishDate,
        dateTime: new Date(Date.now()).toISOString(),
        department: localStorage.getItem("department").split("/")[1].trim(),
      },
    ];
    axios
      .post(
        process.env.REACT_APP_API_URL + "download",
        JSON.stringify(payload),
        {
          headers: {
            "x-api-key": process.env.REACT_APP_X_API_KEY,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((data) => {
        console.log("article read success");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleArrowKeyDown = (e, direction) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      if (direction === "left") {
        goPreviousVersion();
      } else {
        goNextVersion();
      }
    }
  }

  const goPreviousVersion = () => {
    setAppReleaseIndex(appReleaseIndex + 1);
    setShowLoader(true);
    setPosts([]);
    setStartDate(
      dayjs(configData.app_release[appReleaseIndex + 1].startDate).format(
        "MM.DD.YY"
      ) || ""
    );
    setEndDate(
      dayjs(configData.app_release[appReleaseIndex + 1].endDate).format(
        "MM.DD.YY"
      ) || ""
    );
    getEachPost(
      childCategories,
      configData.app_release[appReleaseIndex + 1].posts
    );
  };

  const goNextVersion = () => {
    setAppReleaseIndex(appReleaseIndex - 1);
    setShowLoader(true);
    setPosts([]);
    setStartDate(
      dayjs(configData.app_release[appReleaseIndex - 1].startDate).format(
        "MM.DD.YY"
      ) || ""
    );
    setEndDate(
      dayjs(configData.app_release[appReleaseIndex - 1].endDate).format(
        "MM.DD.YY"
      ) || ""
    );
    getEachPost(
      childCategories,
      configData.app_release[appReleaseIndex - 1].posts
    );
  };

  return (
    <div>
      <div className="page">
        {showLoader ? <Loader showLoader /> : null}
        {isModalOpen && (
          <Modal
            handleClose={(bool) => {
              setFocusRef(bool)
              setIsModalOpen(false);
              // setcurrentPost({});
              document.body.classList.remove("noscroll");
            }}
          >
            <PostContent post={currentPost} />
          </Modal>
        )}
        {featured.length > 0 && (
          <Header posts={featured} openModal={openModal} />
        )}
        <div className="container featured first-item">
          <div className="date-section mb-4">
            {configData &&
              configData.app_release.length - 1 !== appReleaseIndex && (
                <img
                aria-label="View previous section"
                alt="left"
                className="arrow"
                onClick={goPreviousVersion}
                onKeyDown={(e) => handleArrowKeyDown(e, "left")}
                role="button"
                src="../../images/icons/left-arrow.png"
                tabIndex={0}
                />
              )}
            <h4 className="text-center text-white text-uppercase mb-0 martop-1">
              {appReleaseIndex === 0 && "Latest"} News {startDate} - {endDate}
            </h4>
            {appReleaseIndex !== 0 && (
              <img
                aria-label="View to next section"
                alt="right"
                className="arrow"
                onClick={goNextVersion}
                onKeyDown={(e) => handleArrowKeyDown(e, "right")}
                role="button"
                src="../../images/icons/right-arrow.png"
                tabIndex={0}
              />
            )}
          </div>
          <section
            className={
              !(featured.length % 2) ? "article-grid alt-grid" : "article-grid"
            }
          >
            {featured.map((post, index) => {
              return (
                <article key={`latest-${post.postId}`}>
                  <Post focusRef={focusRef && post.postId === currentPost.postId} post={post} openModal={openModal} card />
                </article>
              );
            })}
          </section>
        </div>
        <section className="additional-news">
          <div className="tag">Additional news</div>
          <div className="container">
            {!mobile && (
              <div className="tabs">
                {otherNewsCategories.map((category) => {
                  return (
                    <CategoryTab
                      key={category.id}
                      title={category.name}
                      icon={category.icon}
                      image={category.image}
                      selected={category.id === selectedSlide}
                      click={() => {
                        setSelectedSlide(category.id);
                        // setSlideHeight();
                      }}
                    />
                  );
                })}
              </div>
            )}
            <div className="tabContent" style={{ height: tabContentHeight }}>
              <div id="news-category" ref={contentRef}>
                {otherNewsCategories.map((category) => {
                  return (
                    <div
                      key={category.id}
                      className="slide"
                      style={{
                        display:
                          mobile || category.id === selectedSlide ? "" : "none",
                      }}
                    >
                      {mobile && (
                        <CategoryTab
                          key={category.id}
                          title={category.name}
                          icon={category.icon}
                          image={category.image}
                        />
                      )}
                      <PostSlider
                        currentPost={currentPost}
                        focusRef={focusRef}
                        posts={getPostByCategory(category.id)}
                        openModal={openModal}
                        // slideChanged={setSlideHeight}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        {quarterlyPerspective.length > 0 && (
          <QuarterlyPerspective quarterlyPerspective={quarterlyPerspective} showPerspective={configData.showPerspective} />
        )}
        <Feedback />
        <Footer
          appReleaseIndex={appReleaseIndex}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
}

export default Home;
