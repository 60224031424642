import React from 'react';
import './Feedback.scss';

const Feedback = () => {
    return (
        <>
            <section className="feedbackSection">
                <div className="container-fluid">
                    <div className="feedback section">
                        <div>
                            <img className="chat-icon" src="./images/icons/chat.svg" alt="chat" />
                            <div>
                                <h2>Have questions or feedback? Please contact us</h2>
                                <p><i>The Download is powered by R&D AI Strategy and Execution</i></p>
                            </div>
                        </div>
                        <div>
                            <a href="https://app.smartsheet.com/b/form/deafb5a916af4665be830d32beb6e73d" target="_blank" rel="noopener noreferrer" className="btn btn-outline-light">Contact Us</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default Feedback;