import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import ConfigContext from "../../ConfigContext";
import PostActions from "../PostActions/PostActions";
import "./Posts.scss";

const Post = ({ post, hero, card, focusRef, openModal }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [featureVideo, setFeatureVideo] = useState(null);
  const { configData } = useContext(ConfigContext);
  const image = (post.images && post.images.Box640) || post.images.Box320 || {};
  const headlineRef = useRef(null)

  const getPostVideoURL = () => {
    axios
      .get(`${configData.APP_URL}/post/${post.postId}?include=WatchVideo`)
      .then((videoRes) => {
        let featureVideo = videoRes.data.media.filter(
          (media) => media.role === "video-hosted"
        )[0];
        setFeatureVideo(featureVideo);
        setShowVideo(true);
      });
  };

  const openModalFromPostAction = () => {
    openModal(post);
  };

  const handleHeadingKeyDown = (e, post) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      openModal(post);
    }
  };

  useEffect(() => {
    if (!focusRef) return
    headlineRef.current && headlineRef.current.focus()
  }, [focusRef])

  const chatGPTwhitePaperCard = () => (
    <div>
    <div className="perspective-whitepaper-badge-border"></div>
    <div className="perspective-whitepaper-badge">
      <img
        className="chat-icon"
        src="../../images/icons/magnifying-glass.png"
        height="18px"
        width="18px"
        alt="chat-icon"
      />
      <span>White Paper</span>
    </div>
  </div>
  )

  const postPerspectiveCard = () => (
    <div>
    <div className="perspective-badge-border"></div>
    <div className="perspective-badge">
      <img
        className="chat-icon"
        src="../../images/icons/TheDownload_mark.svg"
        alt="chat-icon"
      />
      <span>Perspective</span>
      <div className="perspective-tooltip">
        Take a deeper dive into this article through commentary
        provided by DH&I, where we assess this headline in
        context of other market activity and interpret the &#x2018;so
        what&#x2019; for Amgen
      </div>
    </div>
  </div>
  )

  const videoNoShowVideo = () =>  (
    <div
    onClick={getPostVideoURL}
    className="feature-image hero"
    style={{ backgroundImage: `url(${image.url})` }}
  >
    <img
      className="video-thumb "
      src="../../images/video_thumb.png"
      alt="video-thumb"
    />
    {post.perspective && card && postPerspectiveCard()}
  </div>
  )

  const showVideoEl = () => (
    <div>
      <video
        src={featureVideo.url}
        width={featureVideo.width}
        className="feature-image video hero"
        autoPlay
        muted
        controls
      >
        Your browser does not support the video tag.
      </video>
      {post.perspective && card && postPerspectiveCard()}
    </div>
  )

  const postContent = () => (
    <div className="content">
              <button 
                className="clamp-3" 
                onClick={() => openModal(post)}
                onKeyDown={(e) => handleHeadingKeyDown(e, post)}
                ref={headlineRef}
                >
                <h3 className="post-title">{post.title}</h3>
              </button>
              <p className="clamp-4">{post.description}</p>

              <PostActions
                postId={post.postId}
                postTitle={post.title}
                publishDate={post.publishDate}
                isLikedByUser={post.isLikedByUser}
                isVideo={post.postType === "Video"}
                openModal={openModalFromPostAction}
                statistics={post.statistics}
              />
            </div>
  )

  return (
    <>
      <div className={card ? "post card" : "post standard"}>
        {hero ? (
          <div>
            {image.url && post.postType !== "Video" && (
              <div
                onClick={() => openModal(post)}
                className="feature-image hero"
                style={{ backgroundImage: `url(${image.url})` }}
              >
                {post.perspective && card && postPerspectiveCard()}
                {(post.title.indexOf('ChatGPT') !== -1 || post.title.indexOf('Whitepaper') !== -1) && card && chatGPTwhitePaperCard()}
              </div>
            )}
            {post.postType === "Video" && !showVideo && videoNoShowVideo()}
            {showVideo && showVideoEl()}
            {postContent()}
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4">
              {image.url && post.postType !== "Video" && (
                <div
                  onClick={() => openModal(post)}
                  className="feature-image non-hero"
                  style={{ backgroundImage: `url(${image.url})` }}
                >
                {post.perspective && card && postPerspectiveCard()}
                {(post.tagLine.indexOf('ChatGPT') !== -1 || post.tagLine.indexOf('Whitepaper') !== -1) && card && chatGPTwhitePaperCard()}
                </div>
              )}
              {post.postType === "Video" && !showVideo && videoNoShowVideo()}
              {showVideo && showVideoEl()}
            </div>
            <div className="col-lg-8">
             {postContent()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Post;
